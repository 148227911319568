import React, { useState } from 'react';
import Heading from 'components/Heading';
import { Columns, Column } from 'components/Columns';
import Button from '@hubins/components/Button';
import AddViewOnlyUserModal from './AddViewOnlyUserModal';
import SimpleTable from 'modules/Tables/SimpleTable';
import middleman from 'helpers/middleman';
import AddProfileToPortfolioRoleModal from './AddProfileToPortfolioRoleModal';
import { rolesTableHeaders } from 'helpers/roles';
import __ from 'localisation';

const PortfolioRoles = ({ portfolio }) => {

  const [addViewOnlyUser, setAddViewOnlyUser] = useState(false);
  const [viewOnlyUsers, setViewOnlyUsers] = useState(portfolio.view_only_users || []);
  const [invitedViewOnlyUsers, setInvitedViewOnlyUsers] = useState(portfolio.invited_view_only_users || []);
  const [roleToAdd, setRoleToAdd] = useState('');
  const [loading, setLoading] = useState(false);

  const policyHolders = portfolio.policy_holder || [];
  const lifeAssured = portfolio.life_assured || [];
  const beneficiaries = portfolio.beneficial || [];

  const deleteRoleForProfile = async (role, profile) => {
    if (confirm(`Are you sure you want to remove ${profile.name} as ${__(role)}?`)) {
      await middleman.post(`/ql/crm/portfolio/remove-role/${portfolio.uuid}`, {
        role,
        profileUuid: profile.uuid,
      });
      window.location.reload();
    }
  };

  const addRoleButton = (role) => {
    return <Button
      onClick={() => { setRoleToAdd(role); }}
      className='transparent'
      icon="add"
      onlyIcon
    />;
  };

  const deleteRoleButton = (role, profile) => {
    return <Button
      loading={loading}
      disabled={loading}
      onlyIcon
      className=''
      icon="Bin by Streamlinehq"
      onClick={() => deleteRoleForProfile(role, profile)} />;
  };

  const tablePolicyHolders = [{
    name: 'Add new policy holder',
    national_identity_number: '',
    action: addRoleButton('policy_holder'),
  }, ...policyHolders.map((profile) => ({
    ...profile,
    action: deleteRoleButton('policy_holder', profile)
  }))];

  const tableBeneficiaries = [{
    name: 'Add new beneficiary',
    national_identity_number: '',
    action: addRoleButton('beneficial'),
  }, ...beneficiaries.map((profile) => ({
    ...profile,
    action: deleteRoleButton('beneficial', profile)
  }))];

  const tableLifeAssured = [{
    name: 'Add new life assured',
    national_identity_number: '',
    action: addRoleButton('life_assured'),
  }, ...lifeAssured.map((profile) => ({
    ...profile,
    action: deleteRoleButton('life_assured', profile)
  }))];

  const deleteViewOnlyUser = async (user) => {
    if (confirm(`Are you sure you want to delete ${user.name} as a view only user?`)) {
      setLoading(true);
      try {
        await middleman.promisePost(`/ql/crm/portfolio/delete-view-only-user`, {
          userUuid: user.uuid,
          portfolioUuid: portfolio.uuid,
        });
        setViewOnlyUsers(viewOnlyUsers.filter((u) => u.uuid !== user.uuid));
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };

  const deleteInvitedViewOnlyUser = async (user) => {
    if (confirm(`Are you sure you want to delete the invitation for ${user.email}?`)) {
      setLoading(true);
      try {
        await middleman.promiseDelete(`/ql/crm/portfolio/delete-invited-view-only-user/${user.id}`);
        setInvitedViewOnlyUsers(invitedViewOnlyUsers.filter((u) => u.id !== user.id));
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  };

  return (
    <Columns>
      <AddProfileToPortfolioRoleModal
        roleToAdd={roleToAdd}
        setRoleToAdd={setRoleToAdd}
        portfolioUuid={portfolio.uuid}
      />
      <AddViewOnlyUserModal
        open={addViewOnlyUser}
        setOpen={setAddViewOnlyUser}
        viewOnlyUsers={viewOnlyUsers}
        setViewOnlyUsers={setViewOnlyUsers}
        portfolioUuid={portfolio.uuid}
        invitedViewOnlyUsers={invitedViewOnlyUsers}
        setInvitedViewOnlyUsers={setInvitedViewOnlyUsers}
      />
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Policy holders
        </Heading>
        <SimpleTable data={tablePolicyHolders} headers={rolesTableHeaders} />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Life assured
        </Heading>
        <SimpleTable data={tableLifeAssured} headers={rolesTableHeaders} />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Beneficiaries
        </Heading>
        <SimpleTable data={tableBeneficiaries} headers={rolesTableHeaders} />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          View only users
        </Heading>
        <SimpleTable data={
          [{
            name: 'Add new user',
            national_identity_number: '',
            action: <Button
              onClick={() => { setAddViewOnlyUser(true); }}
              className='transparent'
              icon="add"
              onlyIcon
            />,
          }, ...viewOnlyUsers.map((profile) => {
            return {
              ...profile,
              action: <Button
                loading={loading}
                disabled={loading}
                onlyIcon
                className=''
                icon="Bin by Streamlinehq"
                size='small'
                onClick={() => deleteViewOnlyUser(profile)} />,
            };
          })]} headers={rolesTableHeaders} />
        <Heading className='s-bottom-md s-top-md' size="4">
          Invited view only users
        </Heading>
        <SimpleTable data={invitedViewOnlyUsers.map((profile) => {
          return {
            ...profile,
            action: <Button
              loading={loading}
              disabled={loading}
              onlyIcon
              className=''
              icon="Bin by Streamlinehq"
              size='small'
              onClick={() => deleteInvitedViewOnlyUser(profile)} />,
          };
        })} headers={rolesTableHeaders} />
      </Column>
    </Columns>
  );
};

export default PortfolioRoles;
