import __ from 'localisation';
import { isOldEnough, formatMoney } from 'helpers';
import { getMarket } from 'helpers/market';
import FinnishSSN from "finnish-ssn";
import { FinnishBusinessIds } from 'finnish-business-ids';
import norwegianSSN from './norwegianSSN';
import norwegianOrganisation from './norwegianOrganisation';

export const checkExpected = (item) => {
  const expected = item.expected;
  if (item.value[expected].value) {
    return true;
  }
  return false;
};

// CreateForm validate if expected value is selected, ex Knowledge test
export const knowledgeValidation = (data) => {
  let keysArray = Object.keys(data);
  let numberOfAnswers = keysArray.length;

  const getSelectedAnswer = (field) => {
    const optionKeys = Object.keys(field.value);
    return optionKeys.find(key => {
      const option = field.value[key];
      return option.value;
    });
  };

  const correctAnswerLength = keysArray.map(key => {
    const item = data[key];
    const answer = getSelectedAnswer(item);
    switch (key) {
      case 'approve_study_materials':
        return true;
      case 'tax_methods':
        return answer === 'alt_2';
      case 'what_is_insured':
        return answer === 'the_life_assured_life' || answer === 'c_the_life_assured_life';
      case 'who_owns_insurance':
        return answer === 'the_policyholder';
      case 'who_is_life_assured':
        return answer === 'life_assured';
      case 'who_receives_payment_in_case_of_insurance':
        return answer === 'the_beneficial_owners';
      case 'owner_of_assets':
        return answer === 'insurance_company_is_registered_as_owner' || answer === 'c_insurance_company_is_registered_as_owner';
      case 'cancel_insurance_whenever':
        return answer === 'yes_but_cost_based_on_insurance_one_year_term' || answer === 'c_yes_but_cost_based_on_insurance_one_year_term';
      case 'truthy_statement_below':
        return answer === 'alt_1';
    }
  }).filter((item) => (item)).length;

  return {
    answers: numberOfAnswers,
    correct: correctAnswerLength,
  };
};

/**
 * [
 * required is assigned to every component in forms if this is not assigned manualy
 * checks if the field have being filled in, or an alternative has been picked
 * ]
 * @param  {string/obj} value [Value to be validated]
 * @param  {string} type  [type of component to be validated ex 'select']
 * @return {bool}         [false if no error, true if there should be generated error]
 */

export const required = (value, type) => {

  switch (type) {
    case 'repeater':
      if (value && typeof value === 'object') {
        return Object
          .keys(value)
          .some((key) => {
            const field = value[key];
            // TODO: this is a temp fix for fixing fields with visibleConditions in repeater
            if (field.visibleConditions && field.visibleConditions.length > 0) {
              return false;
            }
            return required(field.value, field.type);
          });
      }
      return false;
    case 'input':
    case 'textarea':
      return (!value || value.length < 1) && value !== 0 ? __('input_error') : false;
    case 'select':
    case 'dropdown':
    case 'tableselect':
      for (let key in value) {
        if (value[key].value) {
          return false;
        }
      }
      return true;
    case 'file':
      return value.length > 0 ? false : true;
    case 'checkbox':
      return value ? false : true;
    default:
      return false;
  }
};

export const percent = (value) => {
  try {
    const parsedNumber = Number(value);
    if (parsedNumber > 100 || parsedNumber < 0) {
      return __('percent_error_message');
    }
  } catch {
    return __('percent_error_message');
  }
  return false;
};

/**
 * [checks if a 10digit number is a valid luhn number]
 * @param  {string} value [Value to be validated]
 * @return {bool}         [false if no error, true if there should be generated error]
 */

export const luhn = (value) => {

  if (/[^0-9-\s]+/.test(value)) return true;

  let nCheck = 0, nDigit = 0, bEven = false;
  value = value.replace(/\D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  (nCheck % 10) === 0 ? false : true;

};

/**
 *
 * @param {string}           ssn string with the SSN to be validated
 * @param {string}           type type, usually input (not used but send from HandleInputChange)
 * @param {bool}             isRequired is the field a required field?
 * @param {bool}             withAgeLimit should the age limit be checked?
 * @returns {(string|bool)}  String with error message or false}
 */
export const swedishSsn = (ssn = '', type, isRequired, withAgeLimit = true) => {
  if (!ssn && isRequired || !ssn) return __('input_error');
  if (!ssn && !isRequired) return false;


  if (!ssn.match(/^(19|20)\d{6}\d{4}$/)) {
    // errObj.message = __('ssn_validation_error');
    // return errObj;
    return __('ssn_validation_error');
  }

  // Transform 12 digit to 10
  let ssn10digit = ssn;
  if (ssn.length === 12) {
    ssn10digit = ssn.substring(2);
  }

  // Declare variables
  let d = new Date(((RegExp.$1) ? RegExp.$1 : RegExp.$5), (((RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1), ((RegExp.$3) ? RegExp.$3 : RegExp.$7)),
    sum = 0,
    numdigits = ssn10digit.length,
    parity = numdigits % 2,
    i = '',
    digit = '';

  // Check valid date
  if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) {
    // errObj.message = __('ssn_validation_error');
    // return errObj;
    return __('ssn_validation_error');
  }

  // Check if older than 18
  if (withAgeLimit && !isOldEnough(ssn)) {
    // errObj.message = __('error_underaged');
    // return errObj;
    return __('error_underaged');
  }

  // Check luhn algorithm
  for (i = 0; i < numdigits; i = i + 1) {
    digit = parseInt(ssn10digit.charAt(i), 10);
    if (i % 2 === parity) { digit *= 2; }
    if (digit > 9) { digit -= 9; }
    sum += digit;
  }
  const errorInLuhn = !((sum % 10) === 0);
  if (errorInLuhn) {
    // errObj.message = __('error_underaged');
    // return errObj;
    return __('error_underaged');
  }

  // errObj.error = false;
  // return errObj;
  return false;
};

/**
 * @param {string}           value string with the SSN to be validated
 * @param {string}           type type, usually input (not used but send from HandleInputChange)
 * @param {bool}             isRequired is the field a required field?
 * @returns {(string|bool)}  String with error message or false}
 */
export const ssn = (value = '', type, isRequired = true) => {
  if (!value && isRequired) return __('input_error');
  if (!value && !isRequired) return false;

  const market = getMarket();

  switch (market) {
    case "FI":
      return FinnishSSN.validate(value) === false ? __('ssn_validation_error') : false;
    case "NO":
      return norwegianSSN(value) === false ? __('ssn_validation_error') : false;
    case "SE":
      const orgSsn = value;

      if (!value.match(/^(19|20)\d{6}\d{4}$/)) {
        return __('ssn_validation_error');
      }

      // Clean value
      value = value.replace('-', '');
      if (value.length === 12) {
        value = value.substring(2);
      }

      // Declare variables
      let d = new Date(((RegExp.$1) ? RegExp.$1 : RegExp.$5), (((RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1), ((RegExp.$3) ? RegExp.$3 : RegExp.$7)),
        sum = 0,
        numdigits = value.length,
        parity = numdigits % 2,
        i = '',
        digit = '';

      // Check valid date
      if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) {
        return __('ssn_validation_error');
      }

      // Check if older than 18
      if (!isOldEnough(orgSsn)) {
        return __('error_underaged');
      }

      // Check luhn algorithm
      for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(value.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
      }
      const errorInLuhn = !((sum % 10) === 0);
      if (errorInLuhn) {
        return __('ssn_validation_error');
      } else {
        return false;
      }
  }
};

/**
 * [Organisation number for Swedish organisations]
 * @param  {string} value       [orgNumber to be validated]
 * @param {string} type         [type, usually input (not used, but send from HandleInputChange)]
 * @param {bool} isRequired     is the field a required field?
 * @return {bool}               [false if no error, true (a string) if there should be generated error]
 */

export const orgNumber = (nr, type, isRequired) => {
  if (!nr && isRequired) return __('input_error');
  if (!nr && !isRequired) return false;
  /*
  Liksom personnummer består organisationsnumret av tio siffror,
  varav den sista är en kontrollsiffra som kan beräknas ur de föregående nio.
  Till skillnad från personnummer är de första sex siffrorna i
  ett organisationsnummer inte ett datum. För att skilja på personnummer och
  organisationsnummer är alltid "månaden" (andra paret, som byggs upp av tredje
  och fjärde siffran) i ett organisationsnummer minst 20.[2]
  Källa - https://sv.wikipedia.org/wiki/Organisationsnummer
   */

  // Check if just correct pattern is used
  // [0-9]*1, [0-9]*5, -, [0-9]*4 ex: 111111-1111
  // First number is the group number, like 1 = Dödsbon, 5 = Aktiebolag

  const market = getMarket();

  switch (market) {
    case "NO": return !norwegianOrganisation(nr);
    case "FI": return !FinnishBusinessIds.isValidBusinessId(nr);
    case "SE":

      if (!nr.match(/^(\d{1})(\d{5})\-(\d{4})$/)) {
        return true;
      }

      let group = RegExp.$1;
      let controldigits = RegExp.$3;
      let alldigits = group + RegExp.$2 + controldigits;

      if (alldigits.substring(2, 3) < 2) { return true; }
      let nn = "";
      for (let n = 0; n < alldigits.length; n++) { nn += ((((n + 1) % 2) + 1) * alldigits.substring(n, n + 1)); }
      let checksum = 0;
      for (let n = 0; n < nn.length; n++) { checksum += nn.substring(n, n + 1) * 1; }
      return (checksum % 10 === 0) ? false : true;
  }

};

/**
 * [Light check if value is a valid email]
 * @param  {string} value   [value to be validated]
 * @param {string} type     [type, usually input (not used, but send from HandleInputChange)]
 * @param {bool} isRequired [is the field a required field?]
 * @return {bool}           [false if no error, true if there should be generated error]
 */

export const email = (value, type, isRequired = true) => {
  if (!isRequired && !value) return false;
  const re = /\S+@\S+\.\S+/;
  if (re.test(value)) return false;
  return __('error_email');
};

/**
 * [checks if value is a valid phone nummber]
 * @param  {string} value   [string number to be validated]
 * @param {string} type     [type, usually input (not used, but send from HandleInputChange)]
 * @param {bool} isRequired [is the field a required field?]
 * @return {bool}           [false if no error, true if there should be generated error]
 */
export const phone = (value, type, isRequired = true) => {
  if (!isRequired && !value) return false;

  const market = getMarket();
  if (market !== 'SE') {
    return false;
  }
  value = value.replace(/\s+/g, '');
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return !(re.test(value));
};

/**
 * [zip description]
 * @param  {string} value [string number to be validated]
 * @return {bool}         [false if no error, true if there should be generated error]
 */

export const zip = (value) => {
  const market = getMarket();
  if (market !== 'SE') {
    return false;
  }
  const re = /\d{5}/;
  return !(re.test(value));
};

/**
 * [
 * none simply returns false, this is for fields where there is no validation needed
 * in forms this is assigned to every component that dosnt have a assigned validation method
 * ]
 * @return {bool} [always false]
 */

export const none = () => {
  return false;
};

// NOT USED
export const validateNumber = (value, isrequired = false, min = false, max = false, pattern = false, step = false, currency = false) => {
  const _value = Number(value);
  // console.log(value, isrequired, min, max, pattern, step);
  // console.log('value % Number(step)', value % Number(step) !== 0);
  // console.log(__('error_step', { value: Math.round(value) }));

  if (Number.isNaN(_value)) return __('error_number');
  if (min !== false && _value < min) return __('error_min_value', { number: currency ? formatMoney(min, false, 2) : min });
  if (max !== false && _value > max) return __('error_max_value', { number: currency ? formatMoney(max, false, 2) : max });
  if (isrequired && (value === '' || typeof _value === 'undefined')) return __('error_empty');
  if (step && (_value % Number(step) !== 0)) return __('error_step', { value: Math.round(_value) });
  const patternRegexp = `^${pattern}+$`;

  if (pattern && !(new RegExp(patternRegexp).test(value))) return __('error_pattern', { pattern: pattern });

  return false;
};

// Not to be used in  Create form
export const validateText = (value, isrequired = false) => {
  if (isrequired && !value) return __('error_empty');
  return false;
};

// Not to be used in  Create form
export const validateSelect = (value, isrequired = false) => {
  if (isrequired && (!value || value === "false")) return __('error_options');
  return false;
};

// Not to be used in  Create form
export const StandardValidation = (type, value, prop1, prop2, prop3, prop4 = false, prop5 = false) => {
  switch (type) {
    case 'number':
      return validateNumber(value, prop1, prop2, prop3, prop4, prop5, false);
    case 'currency':
      const r = validateNumber(value ? value.replace(/ /g, '') : value, prop1, prop2, prop3, prop4, prop5, true);
      // console.log('value', value, r);
      return r;
    case 'text':
      return validateText(value, prop1);
    case 'email':
      return email(value, prop1);
    case 'select':
      return validateSelect(value, prop1);
    default:
      console.error('This validation method does not excist in StandardValidation: ', type);
      return false;
  }
};

export default { required, none, luhn, ssn, orgNumber, email, phone, zip, percent };
