// Duplicate of packages/modules/Menu/getAvailableRoutes.jsx
import React from 'react';
import Icon from '@hubins/components/IconNew';
import __ from 'localisation';

const getRouteForRole = (role, mobile, approvals) => {
  switch (role) {
    case 'general':
      return [
        {
          title: __('menu_settings'),
          route: '/dashboard/settings',
          iconName: 'Cog',
          icon: <Icon icon="Cog"  />
        }
      ];
    case 'backoffice':
      return [
        {
          title: __('menu_home'),
          route: '/dashboard',
          iconName: 'house',
          icon: <Icon icon="house" size={24}  />,
        },
        {
          title: __('menu_portfolios'),
          route: '/dashboard/clients',
          iconName: 'user',
          icon: mobile
            ? <Icon icon="user" size={24}  />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="users"  spacing="" />,
        },
        {
          title: __('menu_users'),
          route: '/dashboard/profiles',
          iconName: 'user',
          icon: mobile
            ? <Icon icon="user" size={24}  />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="users"  spacing="" />,
        },
        ...approvals ? [
          {
            title: __('menu_approvals'),
            route: '/dashboard/approvals',
            iconName: 'Common File Text by Streamlinehq',
            icon: mobile
              ? <Icon icon="Common File Text by Streamlinehq"   />
              : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="test"  spacing="" />,
          }
        ] : [],
      ];
    case 'hubins_backoffice':
    case 'hubins_compliance':
      return [
        {
          title: __('menu_securities_cms'),
          route: `/dashboard/cms`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq" />,
        },
      ];
    case 'admin':
      return [
        {
          title: __('menu_forms'),
          route: `/formscms`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"   />,
          external: true,
        },
        {
          title: __('menu_files'),
          route: `/dashboard/files`,
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"  />,
        },
        {
          title: __('menu_pending_fa_transactions'),
          route: '/dashboard/pending-fa-transactions',
          iconName: 'Common File Text by Streamlinehq',
          icon: <Icon icon="Common File Text by Streamlinehq"  />,
        },
      ];
    case 'broker':
      return [
        {
          title: __('menu_my_clients'),
          route: '/dashboard/clients',
          iconName: 'users',
          icon: mobile
            ? <Icon icon="user"  />
            : <Icon anim="" ariaHidden={undefined} className=""  fallback="fallback" icon="user"  spacing="" />,
        },
      ];
  }
};

export const getAvailableRoutes = (userRoles, mobile, approvals) => {

  if (!userRoles || userRoles.length < 1) {
    return [];
  }

  const orderedRoles = [
    'backoffice',
    'admin',
    'broker',
    'general',
  ];

  const routes = orderedRoles.reduce((foundRoutes, role) => {

    if (role === 'backoffice') {
      const needles = [
        'hubins_backoffice',
        'hubins_compliance',
        'quantum_backoffice',
        'quantum_compliance'];
      const hasBackOfficeRole = needles.some(needle => userRoles.includes(needle));

      if (hasBackOfficeRole) {
        foundRoutes = [...foundRoutes, ...getRouteForRole(role, mobile, approvals)];
      }
    } else if (userRoles.includes(role) || role === 'general') {
      foundRoutes = [...foundRoutes, ...getRouteForRole(role, mobile, approvals)];
    }

    return foundRoutes;

  }, []);

  return routes;
};

export default getAvailableRoutes;
