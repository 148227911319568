import React, { useEffect, useState } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from 'components/LoadingIndicators';
import middleman  from 'helpers/middleman';
import __ from 'localisation';

const userHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
];

type ApprovalProfilePortfolioSummaryProps = {
  portfolioUuid: string,
  portfolio?: any,
};

export const ApprovalProfilePortfolioSummary = ({ portfolioUuid, portfolio: propsPortfolio }: ApprovalProfilePortfolioSummaryProps) => {

  const [portfolio, setPortfolio] = useState(false);
  const [risk, setRisk] = useState(0);

  const fetchInitialData = async () => {
    const portfolioRes = propsPortfolio ? {data: propsPortfolio} : await middleman.get(`/portfolio/${portfolioUuid}`);
    const riskRes = await middleman.get(`/risk-assessment/${portfolioUuid}`);
    setPortfolio(portfolioRes.data);
    setRisk(riskRes.data || 0);
  };

  useEffect(() => {
    if (portfolioUuid) {
      fetchInitialData();
    }
  }, [portfolioUuid]);

  return (
    <ApprovalProfilePortfolioSummaryTable portfolio={portfolio} risk={risk} />
  );
};

const loadingState = [
  {
    type: 'Policy',
    value: <LoadingBar />,
  },
  {
    type: `${__('policy_holder')} 1`,
    value: <LoadingBar />,
  },
  {
    type: __('risk_category'),
    value: <LoadingBar />,
  },
];

type ApprovalProfilePortfolioSummaryTableProps = {
  portfolio: any,
  risk: number,
};

export const ApprovalProfilePortfolioSummaryTable = ({ portfolio, risk }: ApprovalProfilePortfolioSummaryTableProps) => {

  const userData = (portfolio && risk) ? [
    ...portfolio ? [{
      type: 'Policy',
      value: portfolio.short_name,
    }] : [],
    ...portfolio.policy_holder.map((policyHolder, index) => (
      {
        type: `${__('policy_holder')} ${index + 1}`,
        value: policyHolder.name,
      }
    )
    ),
    ...risk ? [{
      type: __('risk_category'),
      value: risk,
    }] :  [],
  ] : loadingState;

  return (
    <SimpleTable headers={userHeaders} data={userData} />
  );

}

export default ApprovalProfilePortfolioSummary;