import React, { useState, useEffect, useCallback } from 'react';
import middleman from 'helpers/middleman';
import SimpleTable from 'modules/Tables/SimpleTable';
import Button from '@hubins/components/Button';
import RisksHistoryModal from '@hubins/components/RisksHistoryModal';
import DateInput from '@hubins/components/DateInput';
import { getSelectedDatePlusOneDay } from 'helpers/date';
import __ from 'localisation';
import LoadingBar from 'components/LoadingIndicators';
import RisksHistory from '@hubins/components/RisksHistory/RisksHistory';
import ManualUpdateRisk from '@hubins/components/ManualUpdateRisk/ManualUpdateRisk';

const loadingObj = {
  type: <LoadingBar />,
  score: <LoadingBar />,
  history: null,
};

const loadingState = [loadingObj, loadingObj, loadingObj, loadingObj];

const headers = [
  {
    Header: __('type'),
    accessor: 'type'
  },
  {
    Header: __('score'),
    accessor: 'score'
  },
  {
    Header: '',
    accessor: 'history',
    Cell: ({ row }) => {

      const callback = row.original.history;
      return (
        <Button
          onClick={callback}
          className='transparent'
          icon="arrow right 1"
          disabled={!callback}
        >
          {__('view_history')}
        </Button>
      );
    }
  }
];

const HubinsRisks = ({ portfolioUuid }) => {

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(getSelectedDatePlusOneDay());
  const [risks, setRisks] = useState(null);
  const [formattedRisks, setFormattedRisks] = useState([]);
  const [history, setHistory] = useState([]);
  const [riskLoading, setRiskLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(false);

  const search = useCallback(() => {
    setEndDate(getSelectedDatePlusOneDay(date));
  }, [date, setEndDate]);

  const historyCallback = (theRisks) => {
    return theRisks && theRisks.length > 0 ? () => {
      setHistory(theRisks);
    } : null;
  };

  const fetchHubinsRisks = async () => {
    setRiskLoading(true);
    const response = await middleman.get(`/ql/crm/risk-assessment/risk/summary/${portfolioUuid}/${endDate}`);
    setRisks(response.data);
    setFormattedRisks([
      {
        id: 'pep_risk',
        type: __('pep_risk'),
        score: response.data.pep_risk,
        history: historyCallback(response.data.pep_risks)
      },
      {
        id: 'tax_country_risk',
        type: __('tax_country_risk'),
        score: response.data.tax_country_risk,
        history: historyCallback(response.data.tax_country_risks)
      },
      {
        id: 'nationality_risk',
        type: __('nationality_risk'),
        score: response.data.nationality_risk,
        history: historyCallback(response.data.nationality_risks)
      },
      {
        id: 'occupation_risk',
        type: __('occupation_risk'),
        score: response.data.occupation_risk,
        history: historyCallback(response.data.occupation_risks)
      },
      {
        id: 'deposit_amount',
        type: __('deposit_amount_risk'),
        score: response.data.deposit_amount_risk,
        history: historyCallback(response.data.deposit_amount_risks)
      },
      {
        id: 'company_country_founded',
        type: __('company_country_founded_risk'),
        score: response.data.company_country_founded_risk,
        history: historyCallback(response.data.company_country_founded_risks)
      },
      {
        id: 'company_country_registered',
        type: __('company_country_registered_risk'),
        score: response.data.company_country_registered_risk,
        history: historyCallback(response.data.company_country_registered_risks)
      },
      {
        id: 'business_outside_sweden',
        type: __('business_outside_sweden'),
        score: response.data.business_outside_sweden_risk,
        history: historyCallback(response.data.business_outside_sweden_risks)
      },
      {
        id: 'manual_update',
        type: __('manual_update_risk'),
        score: response.data.manual_update_risk,
        history: historyCallback(response.data.manual_update_risks)
      },
      {
        id: 'fma_risk',
        type: __('fma_risk'),
        score: response.data.fma_risk,
        history: false
      },

    ]);
    setRiskLoading(false);
  };

  useEffect(() => {
    fetchHubinsRisks();
  }, [endDate]);

  const closeHistory = () => {
    setHistory([]);
  };


  return (
    <>
      <DateInput
        label={__('date')}
        value={date}
        callback={setDate}
        className='input-max-width'
      />
      <Button
        onClick={search}
        icon="Button Refresh Arrows by Streamlinehq"
        className='s-top-md'
      >
        {__('search')}
      </Button>
      <RisksHistory
        open={showHistory}
        setOpen={setShowHistory}
        portfolioUuid={portfolioUuid}
      />
      <br />
      <Button
        onClick={() => setShowHistory(true)}
        className='s-top-md s-bottom-md'
        icon="Common File Stack by Streamlinehq"
      >
        Full History
      </Button>
      <br />
      <ManualUpdateRisk
        portfolioUuid={portfolioUuid}
        refresh={fetchHubinsRisks}
      />
      <RisksHistoryModal
        data={history}
        callback={closeHistory}
      />
      <SimpleTable
        headers={headers}
        data={(!risks || riskLoading) ? loadingState : formattedRisks}
      />
    </>
  );

};

export default HubinsRisks;
