import React from 'react';
import FMATable from '@hubins/components/FMATable/FMATable';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import { useState } from 'react';
import DueDiligenceResponses from './DueDiligenceResponses';
import HubinsRisks from './HubinsRisks';
import FaNotes from './FaNotes';
import InternalDocuments from './InternalDocuments';

const tabs = [
  'fma',
  'hubins',
  'dd',
  'fa-notes',
  'internal-documents'
];

const RiskTool = ({ profileUuid, portfolioUuid }) => {
  const [index, setIndex] = useState(0);

  useTabs(setIndex, index, portfolioUuid, tabs);
  return (
    <>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={setIndex}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">Risk Tool</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Risk profile</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Due Dilligence Checks</Tab>
          <Tab className="heading heading--5 line-tabs__tab">FA Notes</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Internal documents</Tab>
        </TabList>
        <TabPanel>
          <FMATable profileUuid={profileUuid} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <HubinsRisks portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <DueDiligenceResponses profileUuid={profileUuid} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <FaNotes portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <InternalDocuments portfolioUuid={portfolioUuid} profileUuid={profileUuid} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default RiskTool;
