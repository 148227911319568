import React from 'react';
import SummaryTable from 'components/SummaryTable';
import SummaryItem from 'components/SummaryItem';
import { getFieldValue } from 'helpers/summary';
import {
  displayStatement,
} from 'helpers/formscms';

const Summary = ({ content, response, title, editRoute, className = '' }) => {

  const renderRepeaterFields = (repeater, value, depth) => {
    const indexedFields = repeater.fields.reduce((fields, field) => {
      fields[field.id] = field;
      return fields;
    }, {});

    return value.reduce((rFields, set, index) => {

      const keys = Object.keys(set);

      const setFields = keys.map((key, rIndex) => {
        const field = indexedFields[key];
        return (
          <SummaryItem
            key={`field-${index}-rep-${rIndex}`}
            title={field.title || field.label || field.heading}
            value={getFieldValue(field, set)}
            depth={depth}
          />);
      });

      return [...rFields, ...setFields];
    }, []);
  };

  return <SummaryTable
    heading={title}
    editRoute={editRoute}
    className={className}
  >
    {content.reduce((arr, section) => {
      if (section.fields) {
        section.fields.forEach(field => {

          const hasVisibleConditions = field.visibleConditions && field.visibleConditions.length > 0;
          const isVisible = typeof response[field.id] !== 'undefined' && response[field.id] !== null;

          if (
            displayStatement(field)
                  || !isVisible
          ) {
            return;
          }

          if (field.type === 'repeater') {
            const summaryItems = renderRepeaterFields(field, response[field.id], hasVisibleConditions ? 1 : 0);
            arr.push([...summaryItems]);
            return;
          }

          arr.push([
            <SummaryItem
              key={field.id}
              title={field.title || field.label || field.heading}
              value={getFieldValue(field, response)}
              depth={hasVisibleConditions ? 1 : 0}
            />,
          ]);

        });
      }


      return arr;
    }, [])}
  </SummaryTable>;
};

export default Summary;
