import React from 'react';
import __ from 'localisation';
import LoadingBar from 'components/LoadingIndicators';
import SimpleTable from 'modules/Tables/SimpleTable';

const headers = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
]

const SecurityTable = ({ security }) => {

  const data = [
    {
      type: __('name'),
      value: security ? security.name : <LoadingBar small />,
    },
    {
      type: __('type'),
      value: security ? security.type : <LoadingBar small />,
    },
    {
      type: __('isin'),
      value: security ? security.isinCode : <LoadingBar small />,
    },
    {
      type: __('currency'),
      value: security ? security.currency : <LoadingBar small />,
    },
    {
      type: 'Issue date',
      value: security ? security.issueDate : <LoadingBar small />,
    },
    {
      type: 'Maturity date',
      value: security ? security.maturityDate : <LoadingBar small />,
    }
  ];

  return (
    <SimpleTable headers={headers} data={data} />
  )

};

export default SecurityTable;