import React, { useEffect, useState } from 'react';
import Button from '@hubins/components/Button';
import Heading from 'components/Heading';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import Checkbox from 'components/Checkbox';
import { connect } from 'react-redux';
import { useFilteredApprovals } from 'queries/approvals';
import { tableHeaders, roleOptions, actionOptions, typeOptions, statusOptions, loadingState } from 'helpers/approvals';
import Input from '@hubins/components/Input';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';

const FilteredApprovals = ({ uuid }) => {

  const [role, setRole] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('pending');
  const [action, setAction] = useState('');
  const [showActive, setShowActive] = useState(false);
  const [filterArgs, setFilterArgs] = useState(false);
  const [nationalIdentityNumber, setNationalIdentityNumber] = useState(false);
  const [shortName, setShortName] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchApprovals = async () => {
    let args = {
      roles: {},
      actions: {},
      sections: {},
    };

    if (role) {
      args.roles = { ...args.roles, name: role };
    }

    if (type) {
      args.sections.type = type;
    }

    if (status) {
      if (status === 'approved') {
        args.actions = {
          status: 'executed',
        };
      } else if (status === 'pending') {
        args.actions = {
          status: 'init'
        };
      } else {
        args.roles = {
          ...args.roles,
          status: 'denied',
        };
      }
    }

    if (action) {
      args.actions = {
        ...args.actions,
        type: action,
      };
    }

    if (showActive) {
      args.verdicts = {
        user_uuid: uuid,
      };
    }

    if (nationalIdentityNumber) {
      args.nationalIdentityNumber = nationalIdentityNumber;
    }

    if (shortName) {
      args.shortName = shortName;
    }

    setFilterArgs(args);
    setPage(0);
  };

  const { data, isFetched, isLoading, refetch } = useFilteredApprovals(filterArgs, page + 1);

  useEffect(() => {
    if (isFetched && !hasFetched) {
      setTotalPages(data.pages);
      setHasFetched(true);
    }
  }, [isFetched]);

  const refresh = () => {
    refetch();
  };

  return (
    <Columns>
      <Column s="4">
        <Dropdown
          label={"Filter by role"}
          options={roleOptions}
          value={role}
          callback={setRole}
          placeholder="Role"
        />
      </Column>
      <Column s="4">
        <Dropdown
          label={"Filter by section type"}
          options={typeOptions}
          value={type}
          callback={setType}
          placeholder="Type"
        />
      </Column>
      <Column s="4">
        <Dropdown
          label={"Filter by status"}
          options={statusOptions}
          value={status}
          callback={setStatus}
          placeholder="Status"
        />
      </Column>
      <Column s="4">
        <Dropdown
          label={"Filter by action"}
          options={actionOptions}
          value={action}
          callback={setAction}
          placeholder="Action"
        />
      </Column>
      <Column s="4">
        <Input
          type="text"
          label="National identity number"
          value={nationalIdentityNumber}
          callback={setNationalIdentityNumber}
        />
      </Column>
      <Column s="4">
        <Input
          type="text"
          label="Insurance number"
          value={shortName}
          callback={setShortName}
        />
      </Column>
      <Column s="12">
        <Button className="medium" onClick={refresh} icon="Button Refresh Arrows by Streamlinehq">
          Refresh
        </Button>
        <Button className="cta medium s-left-m s-right-m" onClick={fetchApprovals}>
          Search
        </Button>
        <Checkbox
          className="justify-start s-top-xl"
          optionsState={{
            error: false,
            value: showActive
          }}
          label={"Only show updated by me"}
          callback={setShowActive}
        />
      </Column>
      <Column s="12">
        <ApiPaginationTable
          headers={tableHeaders}
          data={(isLoading) ? loadingState :
          !data || !data.result || !isFetched ? [] : data.result}
          fetchData={setPage}
          pageCount={totalPages}
          index={page}
        />
      </Column>
    </Columns>
  );

};

function mapStateToProps(state) {
  return {
    uuid: state.auth.User.uuid,
  };
}

export default connect(mapStateToProps)(FilteredApprovals);
