import React, { useEffect, useState, useCallback } from 'react';
import middleman from 'helpers/middleman';
import Button from '@hubins/components/Button';
import { /*rolesTableHeaders,*/ rolesLoadingState } from 'helpers/roles';
import { Columns, Column } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';
import AddUserToProfileModal from './AddUserToProfileModal';
import __ from 'localisation';

const rolesTableHeaders = [
  {
    Header: __('name'),
    accessor: 'name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: 'national_identity_number',
  }
];


const ProfileRoles = ({ profileUuid }) => {
  const [signatories, setSignatories] = useState(rolesLoadingState);
  const [beneficialOwners, setBeneficialOwners] = useState(rolesLoadingState);
  const [
    investmentRepresentatives,
    setInvestmentRepresentatives
  ] = useState(rolesLoadingState);
  const [roleToAdd, setRoleToAdd] = useState('');
  const [loading, setLoading] = useState(false);



  const fetchProfileWithRoles = useCallback(async () => {

    setLoading(true);

    const addRoleButton = (role) => {
      return <Button
        onClick={() => { setRoleToAdd(role); }}
        className='transparent'
        icon="add"
        onlyIcon
      />;
    };

    const deleteRoleForProfile = async (role, profile) => {
      if (confirm(`Are you sure you want to remove ${profile.name} as ${__(role)}?`)) {
        // await middleman.post(`/ql/crm/portfolio/remove-role/${portfolio.uuid}`, {
        //   role,
        //   profileUuid: profile.uuid,
        // });
        window.location.reload();
      }
    };

    const deleteRoleButton = (role, profile) => {
      return <Button
        loading={loading}
        disabled={loading}
        onlyIcon
        className=''
        icon="Bin by Streamlinehq"
        onClick={() => deleteRoleForProfile(role, profile)} />;
    };

    try {
      const response = await middleman.get(`/profile/${profileUuid}`);
      const profile = response.data;
      const rawSignatories = profile.signatories || [];
      const rawBeneficialOwners = profile.beneficial_owners || [];
      const rawInvestmentRepresentatives = profile.investment_representatives || [];

      const signatoriesWithTableData = [
        // {
        //   name: 'Add new signatory',
        //   national_identity_number: '',
        //   action: addRoleButton('signatory'),
        // },
        ...rawSignatories.map((prof) => ({
          ...prof,
          action: deleteRoleButton('signatory', prof)
        }))
      ];



      const beneficialOwnersWithTableData = [
        // {
        //   name: 'Add new beneficial owner',
        //   national_identity_number: '',
        //   action: addRoleButton('beneficial_owner'),
        // },
        ...rawBeneficialOwners.map((prof) => ({
          ...prof,
          action: deleteRoleButton('beneficial_owner', prof)
        }))
      ];

      const investmentRepresentativesWithTableData = [
        // {
        //   name: 'Add new investment representative',
        //   national_identity_number: '',
        //   action: addRoleButton('investment_representative'),
        // },
        ...rawInvestmentRepresentatives.map((prof) => ({
          ...prof,
          action: deleteRoleButton('investment_representative', prof)
        }))
      ];

      setSignatories(signatoriesWithTableData);
      setBeneficialOwners(beneficialOwnersWithTableData);
      setInvestmentRepresentatives(investmentRepresentativesWithTableData);

    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  }, [
    profileUuid,
    setSignatories,
    setBeneficialOwners,
    setInvestmentRepresentatives,
    setLoading,
    setRoleToAdd,
    loading]);

  useEffect(() => {
    fetchProfileWithRoles();
  }, []);

  return (
    <Columns>
      <AddUserToProfileModal
        roleToAdd={roleToAdd}
        setRoleToAdd={setRoleToAdd}
        profileUuid={profileUuid}
      />
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Signatories
        </Heading>
        <SimpleTable
          headers={rolesTableHeaders}
          data={signatories}
        />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Beneficial owners
        </Heading>
        <SimpleTable
          headers={rolesTableHeaders}
          data={beneficialOwners}
        />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Investment representatives
        </Heading>
        <SimpleTable
          headers={rolesTableHeaders}
          data={investmentRepresentatives}
        />
      </Column>
    </Columns>
  );

};

export default ProfileRoles;
