import React, { useState } from 'react';
import FormHistory from 'modules/FormHistory';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import './index.scss';

const privateTabs = [
  'roaring',
  'presale',
  'kt',
  'da_terms',
  'da_purpose',
  'da_transactions',
  'da_products',
  'da_target_return',
  'da_tax',
  'da_beneficiaries',
  'da_so',
  'ins',
  'terms',
  'aftersale',
];

const companyTabs = [
  'presale',
  'kt',
  'da_terms',
  'da_purpose',
  'da_transactions',
  'da_products',
  'da_target_return',
  'da_tax',
  'da_beneficiaries',
  'da_so',
  'ins',
  'bi',
  'hm',
  'terms',
  'aftersale'
];

const History = ({ match, location, client }) => {
  const { profileUuid, portfolioUuid } = match.params;
  const [index, setIndex] = useState(0);

  const changeTab = (newIndex) => {
    setIndex(newIndex);
  };

  const isCompany = client && client.type === 'COMPANY';
  const extension = isCompany ? 'c' : 'p';
  const contactName = client.name || '';
  const tabs = isCompany ? companyTabs : privateTabs;
  useTabs(setIndex, index, location.hash, tabs);

  return (
    <>
      <div className="history-header">
        <Button
          className="spacing withicon withicon--left link large"
          href={`/dashboard/clients/${profileUuid}/${portfolioUuid}/tabs/registration${typeof tabs[index] === 'undefined' ? '' : `#${tabs[index]}`}`}
        >
          <Icon label="back" icon="arrow-left" size={16} />
          Close Version History
        </Button>
      </div>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={changeTab}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list">
          {!isCompany && (
            <Tab className="heading heading--4 line-tabs__tab">Basinformation</Tab>
          )}
          <Tab className="heading heading--4 line-tabs__tab">Förköpsinformation</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Kunskapstest</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Behovsprövning villkor </Tab>
          <Tab className="heading heading--4 line-tabs__tab">Syfte</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Transaktioner</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Tillgångsslag</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Målavkastning</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Skatt & avgifter</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Förmånstagare</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Behovsprövning Godkännande</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Försäkringsinformation</Tab>
          {isCompany && (
            <>
              <Tab className="heading heading--4 line-tabs__tab">Basinformation</Tab>
              <Tab className="heading heading--4 line-tabs__tab">Kundkännedom Bolagsrepresentant</Tab>
            </>
          )}
          <Tab className="heading heading--4 line-tabs__tab">Villkor</Tab>
          <Tab className="heading heading--4 line-tabs__tab">Efterköpsinformation</Tab>
        </TabList>
        {!isCompany && (
          <TabPanel className={`line-tabs__tab-panel`}>
            <FormHistory
              profileUuid={profileUuid}
              portfolioUuid={portfolioUuid}
              formKey={`p_roaring`}
              contactName={contactName}
            />
          </TabPanel>
        )}
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_presale`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_kt`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_terms`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_purpose`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_transactions`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_products`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_target_return`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_tax`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_beneficiaries`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_da_so`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_ins`}
            contactName={contactName}
          />
        </TabPanel>
        {isCompany && (
          <>
            <TabPanel className={`line-tabs__tab-panel`}>
              <FormHistory
                profileUuid={profileUuid}
                portfolioUuid={portfolioUuid}
                formKey={`c_bi`}
                contactName={contactName}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <FormHistory
                profileUuid={profileUuid}
                portfolioUuid={portfolioUuid}
                formKey={`c_hm`}
                contactName={contactName}
              />
            </TabPanel>
          </>
        )}
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_terms`}
            contactName={contactName}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FormHistory
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            formKey={`${extension}_aftersale`}
            contactName={contactName}
          />
        </TabPanel>
      </Tabs>
    </>
  );

};

export default History;
