import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import FormsSummary from 'modules/FormsSummary/Summary';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getSummary } from 'helpers/form';
import Heading from 'components/Heading';
import ApprovalProfilePortfolioSummary from '@hubins/components/ApprovalProfilePortfolioSummary';
import ApprovalTransactionsTable from '@hubins/components/ApprovalTransactionsTable/ApprovalTransactionsTable';
import ClientDocumentsAccordeon from '@hubins/components/ClientDocumentsAccordeon';
import { formatNumberByThousands } from 'helpers';
import { useTransactions } from 'queries/report';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import ReadMore from 'components/Accordeon/ReadMore';
import CompanyPersonnelPowersOfAttorney from '@hubins/components/CompanyPersonnelPowersOfAttorney';
import { useSecurity } from 'queries/securities';
import SecurityTable from '@hubins/components/SecurityTable';

import __ from 'localisation';

const Tof = ({ transaction_uuid, endDate }) => {
  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [transaction, setTransaction] = useState(false);
  const [purposeSummary, setPurposeSummary] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [policyHolder, setPolicyHolder] = useState(false);
  const [signatories, setSignatories] = useState([]);
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const [risk, setRisk] = useState(false);
  const [sourceOfWealth, setSourceOfWealth] = useState(false);

  const { data: security } = useSecurity(transaction?.security_id);

  const {
    data: faTransactions,
    isLoading:
    faTransactionsLoading,
    isFetched: faTransactionsFetched
  } = useTransactions(
    '2010-01-01',
    endDate ? endDate : new Date().toISOString().slice(0, 10),
    portfolio
      ? portfolio.uuid : null
  );

  const sourceHeaders = [
    {
      Header: __('sof'),
      Cell: ({ row }) => __(`sof_${row.original.type}`),
    },
    {
      Header: __('amount'),
      accessor: 'transactionAmount',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: formatNumberByThousands(sources.reduce((res, item) => {
        res += item.transactionAmount;
        return res;
      }, 0))
    }
  ];

  const sourceOfFundsHeaders = [
    {
      Header: __('type'),
      Cell: ({ row }) => __(`sof_${row.original.type}`),
    },
    {
      Header: __('available_amount'),
      accessor: (risk && risk > 2) ? 'highRiskAvailableAmount' : 'availableAmount',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sourceOfWealth ? formatNumberByThousands(sourceOfWealth.reduce((res, item) => {
        const availableAmount = risk > 2 ? item.highRiskAvailableAmount : item.availableAmount;
        res += availableAmount;
        return res;
      }, 0)) : 0
    },
    {
      Header: __('used_amount'),
      accessor: 'usedAmount',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sourceOfWealth ? formatNumberByThousands(sourceOfWealth.reduce((res, item) => {
        res += item.usedAmount;
        return res;
      }, 0)) : 0
    }
  ];

  const sourceOfWealthHeaders = [
    {
      Header: __('sow'),
      Cell: ({ row }) => __(`sof_${row.original.type}`),
    },
    {
      Header: __('amount'),
      accessor: 'source.amounts.source_of_wealth',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sourceOfWealth ? formatNumberByThousands(sourceOfWealth.reduce((res, item) => {
        res += item.source.amounts.source_of_wealth;
        return res;
      }, 0)) : 0
    }
  ];


  const fetchInitData = async () => {
    setLoading(true);
    try {
      const sofPromise = middleman.get(`/ql/crm/source-of-funds/transaction/${transaction_uuid}`);
      const transactionPromise = middleman.get(`/ql/crm/transactions/get/${transaction_uuid}`);

      const result = await Promise.all([sofPromise, transactionPromise]);
      const [sof, transactionRes] = result;
      const portfolioRes = await middleman.get(`/portfolio/${transactionRes.data.portfolio_uuid}`);
      const foundPolicyHolder = portfolioRes.data.policy_holder[0] || false;

      if (foundPolicyHolder && foundPolicyHolder.type === 'COMPANY') {
        const profileRes = await middleman.get(`/profile/${foundPolicyHolder.uuid}?with=powersofattorney`);
        setSignatories(profileRes.data.signatories);
        setBeneficialOwners(profileRes.data.beneficial_owners);
      }
      const riskRes = await middleman.get(`/risk-assessment/${transactionRes.data.portfolio_uuid}`);
      const riskCategory = riskRes.data;
      setRisk(riskCategory);
      setPolicyHolder(foundPolicyHolder);
      const isCompany = foundPolicyHolder.type === 'COMPANY';
      const purposeFormKey = isCompany ? 'c_da_purpose' : 'p_da_purpose';
      getSummary(purposeFormKey, foundPolicyHolder.uuid, transactionRes.data.portfolio_uuid, setPurposeSummary, undefined, { endDate });
      const sofResult = sof.data;
      const sofResultWithTransactionAmount = sofResult.map(item => {
        item.transactionAmount = item.transactions.reduce((acc, trans) => {
          if (trans.transaction_uuid === transaction_uuid) {
            return acc += Number(trans.amount);
          }
          return acc;
        }
        , 0);
        return item;
      });
      const sourceOfWealthRes = await middleman.get(`/ql/crm/source-of-funds/${foundPolicyHolder.uuid}`);
      setSourceOfWealth(sourceOfWealthRes.data);
      setSources(sofResultWithTransactionAmount);
      setTransaction(transactionRes.data);
      setPortfolio(portfolioRes.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  if (loading) return (<LoadingBox />);

  return (
    <>
      <ApprovalProfilePortfolioSummary portfolioUuid={portfolio?.uuid} />
      <div className='maxwidth s-top-xl'>
        <CompanyPersonnelPowersOfAttorney
          signatories={signatories}
          beneficialOwners={beneficialOwners}
          profileId={policyHolder?.id}
        />
      </div>
      <ClientDocumentsAccordeon
        portfolioUuid={portfolio?.uuid}
        profileUuid={policyHolder?.uuid}
        policyHolderName={policyHolder?.name}
      />
      {purposeSummary ? (
        <FormsSummary
          className="s-top-xl"
          content={purposeSummary.content}
          response={purposeSummary.response}
          title={__("purpose")}
        />
      ) : <LoadingBox />}
      <Heading className="s-bottom-lg" size="4">
        {__('user_sources_for_tof')}
      </Heading>
      <SimpleTable headers={sourceHeaders} data={sources} footer />
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('sof')}
      </Heading>
      <SimpleTable data={sourceOfWealth} headers={sourceOfFundsHeaders} footer />
      {sourceOfWealth && (
        <>
          <Heading size="4" className="s-bottom-lg s-top-xl">
            {__('sow')}
          </Heading>
          <SimpleTable
            className="s-top-xl"
            headers={sourceOfWealthHeaders}
            data={sourceOfWealth}
            title="Source of wealth"
            footer
          />
        </>
      )}
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('securities')}
      </Heading>
      <SecurityTable security={security} />
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('transaction_info')}
      </Heading>
      <ApprovalTransactionsTable transactions={[transaction]} />
      <Heading size="4" className={'s-top-xl s-bottom-lg'}>
        {__('transactions')}
      </Heading>
      <ReadMore collapsedHeight={100}>
        <TransactionsTable
          data={
            (faTransactionsFetched && faTransactions && faTransactions.transactions)
              ? faTransactions.transactions
              : []}
          isLoading={faTransactionsLoading}
          currency={portfolio.currency}
        />
      </ReadMore>
    </>
  );
};

export default Tof;
