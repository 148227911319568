import React, { useState, useCallback } from 'react';
import Heading from 'components/Heading';
import SimpleTable from 'modules/Tables/SimpleTable';
import InputCurrency from '@hubins/components/Input/Currency';
import Button from '@hubins/components/Button';
import SecurityTable from '@hubins/components/SecurityTable';
import __ from 'localisation';

type Props = {
  security: any;
  setAmount: (amount: number) => void;
  amount: number;
}

const Step2 = ({ security, setAmount, amount }: Props) => {

  const [internalAmount, setInternalAmount] = useState<string | number>(amount || 0);
  const [error, setError] = useState<boolean>(false);

  const proceed = useCallback(() => {
    if (!internalAmount) {
      setError(true);
      return;
    }
    setAmount(Number(internalAmount));
  }, [internalAmount, setAmount, setError])

  return (
    <>
      <Heading size="3" className="s-top-xl s-bottom-lg">
        {security.name}
      </Heading>
      <SecurityTable security={security} />
      <div className='s-bottom-lg' />
      <InputCurrency
        label={__('choose_amount')}
        className='input-max-width s-top-xl'
        value={internalAmount}
        callback={setInternalAmount}
        error={error ? __('empty_input_message') : ''}
        suffix='SEK'
      />
      <Button
        onClick={proceed}
        className='cta s-top-xl large'
        icon="arrow right 1"
        iconColor='white'
      >
        {__('continue')}
      </Button>
    </>
  )

};

export default Step2;