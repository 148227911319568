import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useRisksHistory = (portfolioUuid, shouldRun) => useQuery(['risksHistory', portfolioUuid], () => {
  return middleman.promiseGet(`/ql/crm/risk-assessment/risk/history/${portfolioUuid}`).then(response => response.data);
} , {
  enabled: shouldRun,
});