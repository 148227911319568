import React, { useState, useCallback } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import __ from 'localisation';

type Props = {
  portfolioUuid: string;
  profileUuid: string;
};

const CreateEntry = ({ portfolioUuid, profileUuid }: Props) => {

  const [security, setSecurity] = useState<object | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [completed, setCompleted] = useState<boolean>(false);

  const reset = () => {
    setSecurity(null);
    setAmount(0);
    setCompleted(false);
  }

  const getStep = useCallback(() => {

    if (completed) {
      return <Step4 reset={reset} />;
    }

    if (amount) {
      return <Step3
        amount={amount}
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
        setCompleted={setCompleted}
        security={security}
      />;
    }

    if (security) {
      return <Step2
        security={security}
        setAmount={setAmount}
        amount={amount}
        />;
    }

    return <Step1 setSecurity={setSecurity} />;

  }, [security, amount, setSecurity, portfolioUuid, profileUuid, setAmount, setCompleted, completed]);

  return (
    <>
      {security && (
        <Button
          className='s-top-xl'
          onClick={() => amount ? setAmount(0) : setSecurity(null)}
          icon="arrow left 1"
          iconLeft
        >
          {__('back')}
        </Button>
      )}
      {getStep()}
    </>
  )

};

export default CreateEntry;