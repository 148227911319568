import React, { useState, useEffect } from 'react';
import { Menu, MenuDropdown, MenuLink, MainMenu } from 'modules/Menu';
import { __, __route } from 'localisation';
import { connect } from 'react-redux';
import ErrorBoundary from 'components/ErrorBoundary';
import getAvailableRoutes from './getAvailableRoutes';
import { useFeature } from "@growthbook/growthbook-react";
import './index.scss';

const LoggedinMenuQLCrm = (props) => {

  const APPROVALS_FF = useFeature('approvals').on;
  const routes = getAvailableRoutes(props.userRoles, false, APPROVALS_FF);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }, [props.path]);

  return (
    <Menu logo={props.logo}>
      <MainMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        logo={props.logo}
        rootUrl={__route('root')}
      >
        <ErrorBoundary>
          {routes.map((route) => {
            return (
              <MenuLink external={!!route.external} icon={route.icon} to={route.route} label={route.title} path={props.path} key={route.title} main />
            );
          })}
        </ErrorBoundary>
      </MainMenu>
      <ErrorBoundary>
        <MenuDropdown
          setMobileMenuOpen={setMobileMenuOpen}
          name={props.name}
          links={[
            {
              to: __route('logout'),
              label: __('logout'),
              color: 'c-danger'
            }
          ]}>

        </MenuDropdown>
      </ErrorBoundary>
    </Menu>
  );
};

function mapStateToProps(state) {
  return {
    name: state.auth.User.name,
    userRoles: state.auth.User.userRoles,
  };
}

export default connect(
  mapStateToProps,
)(LoggedinMenuQLCrm);
