import React, { useState } from 'react';
import PortfolioRoles from './PortfolioRoles';
import ProfileRoles from './ProfileRoles';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import Connections from './Connections';
import RoleHistory from './RoleHistory';

const tabs = [
  'portfolio',
  'profile',
  'connections',
  'policy_role_history',
];

const Roles = ({ portfolio, profileUuid, hash }) => {

  const [index, setIndex] = useState(0);

  useTabs(setIndex, index, hash, tabs);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">Portfolio</Tab>
        <Tab className="heading heading--5 line-tabs__tab">Profile</Tab>
        <Tab className="heading heading--5 line-tabs__tab">Connections</Tab>
        <Tab className="heading heading--5 line-tabs__tab">Policy Role History</Tab>
      </TabList>
      <TabPanel>
        <PortfolioRoles portfolio={portfolio} />
      </TabPanel>
      <TabPanel>
        <ProfileRoles profileUuid={profileUuid} />
      </TabPanel>
      <TabPanel>
        <Connections portfolioUuid={portfolio.uuid} />
      </TabPanel>
      <TabPanel>
        <RoleHistory portfolioUuid={portfolio.uuid} />
      </TabPanel>
    </Tabs>
  );

};

export default Roles;
