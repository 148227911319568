import React, { useEffect, useState } from "react";
import middleman from "helpers/middleman";
import LoadingBar from "components/LoadingIndicators";
import SimpleTable from "modules/Tables/SimpleTable";
import { useNotificationContext } from "@hubins/components/NotificationContext/NotificationContext";
import Button from "@hubins/components/Button";
import { TransactionType } from "../../../types/transactions";
import __ from "localisation";

const headers = [
  {
    Header: __("amount"),
    accessor: "amount",
  },
  {
    Header: __('type'),
    accessor: "type",
  },
  {
    Header: __("currency"),
    accessor: "currency",
  },
  {
    Header: __("security_id"),
    accessor: "security_id",
  },
  {
    Header: __("security_name"),
    accessor: "security.name",
  },
  {
    Header: __("status"),
    accessor: "status",
  },
  {
    Header: __("created_at"),
    accessor: "created_at",
  },
  {
    Header: __("delete"),
    accessor: "delete",
  },
];

const loadingItem = {
  amount: <LoadingBar />,
  currency: <LoadingBar />,
  status: <LoadingBar />,
  delete: <LoadingBar />,
};

const loadingState = [loadingItem, loadingItem];

type Props = {
  portfolioUuid: string;
};

const PendingTransactions = ({ portfolioUuid }: Props) => {
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { setNotification } = useNotificationContext();

  const deleteTransaction = async (transactionUuid: string) => {
    try {
      if (window.confirm(__("confirm_delete_transaction"))) {
        setLoading(true);
        await middleman.promiseDelete(
          `/ql/crm/transaction/delete/${transactionUuid}`,
        );
        fetchTransactions();
      }
    } catch (err) {
      setLoading(false);
      setNotification({
        type: "success",
        message: `Something went wrong fetching pending transactions: ${err.message}`,
      });
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const res = await middleman.promisePost("/ql/crm/transactions/show?withSecurity=true", {
        status: "pending",
        portfolioUuids: [portfolioUuid],
      });
      // TODO: check for possible side-effects
      const formattedTransactions = res.data.map((transaction) => ({
        ...transaction,
        amount: transaction.amount / 100,
        delete: (
          <Button
            onClick={() => deleteTransaction(transaction.uuid)}
            onlyIcon
            icon="Bin by Streamlinehq"
          />
        ),
      }));
      setTransactions(formattedTransactions);
    } catch (err) {
      setNotification({
        type: "success",
        message: `Something went wrong deleting transaction: ${err.message}`,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <SimpleTable
      headers={headers}
      data={loading ? loadingState : transactions}
    />
  );
};

export default PendingTransactions;
