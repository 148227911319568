import React from 'react';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';
import __ from 'localisation';

type Props = {
  reset: () => void;
};

const Step4 = ({reset}: Props) => {

  return (
    <>
      <Heading size="2" className="s-top-xl s-bottom-xl">
        {__('entry_completed')}
      </Heading>
      <Button onClick={reset} className='cta'>
        {__('create_new_entry')}
      </Button>
    </>
  )

};

export default Step4;