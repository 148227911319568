import React, { useRef } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';

const roleTranslations = {
  'policy_holder': 'Policy holder',
  'life_assured': 'Life assured',
  'beneficial': 'Beneficiary',
  'investment_representative': 'Investment representative',
  'signatory': 'Signatory',
  'beneficial_owner': 'Beneficial owner'
};

const headers = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Role in current policy',
    accessor: 'roleCurrent',
  },
  {
    Header: 'Other policy number',
    accessor: 'shortName'
  },
  {
    Header: 'Risk',
    accessor: 'risk'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Role in other policy',
    accessor: 'roleOther'
  },
];

const addCurrentRole = (item, role) => {
  item.role = role;
  return item;
};

const Connections = ({ connections }) => {

  const personnel = useRef(false);
  if (personnel.current === false) {
    const mergedPersonnel = [
      ...connections.beneficial.map(item => addCurrentRole(item, 'Beneficial owner')),
      ...connections.lifeAssured.map(item => addCurrentRole(item, 'Life assured')),
      ...connections.policyHolder.map(item => addCurrentRole(item, 'Policy holder'))
    ];
    const uniqueIndexedPersonnel = mergedPersonnel.reduce((collection, item) => {
      const currentCollectionItem = collection[item.national_identity_number];
      if (currentCollectionItem) {
        currentCollectionItem.role = `${currentCollectionItem.role}, ${item.role}`;
      } else {
        collection[item.national_identity_number] = item;
      }
      return collection;
    }, {});
    const data = Object.keys(uniqueIndexedPersonnel).map(key => {
      const person = uniqueIndexedPersonnel[key];
      const name = person.name;
      const roleCurrent = person.role;
      const tableData = person.connections.map((item) => {
        const { shortName, type } = item;
        const roleOther = item.role.map(role => roleTranslations[role]).toString();
        return {
          name,
          roleCurrent,
          shortName,
          risk: '',
          type,
          roleOther,
        };
      });
      return tableData;
    }
    ).reduce((merged, item) => {
      return [...merged, ...item];
    }, []);


    personnel.current = data;
  }

  return (
    <>
      {personnel.current.length > 0 ? (
        <SimpleTable
          headers={headers}
          data={personnel.current}
        />
      ) : (
        <Heading element="h4" size={4}>
          No connections found
        </Heading>
      )}

    </>

  );
};

export default Connections;
