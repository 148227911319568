import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { __ } from 'localisation';
import Overview from './Overview';
import Report from './Report';
import Transactions from './Transactions';
import Holdings from './Holdings';
import Registration from './Registration';
import YearlyReport from './YearlyReport';
import Roles from './Roles';
import Approvals from './Approvals';
import Documents from './Documents';
import Tags from './Tags';
import BusinessProfile from './NEW_BusinessProfile';
import { isBackofficeUser } from 'helpers/userRoles';
import AptitudeTests from './AptitudeTests';
import SourceOfFunds from './SourceOfFunds';
import PendingTransactions from './PendingTransactions';
import RiskTool from './RiskTool';
import CreateEntry from './CreateEntry';

const tabIndexes = {
  'overview': 0,
  'holdings': 1,
  'transactions': 2,
  'report': 3,
  'yearly-report': 4,
  'documents': 5,
  'aptitude-tests': 6,
  'registration': 7,
  'tags': 8,
  'portfolio-roles': 9,
  'approvals': 10,
  'business-profile': 11,
  'source-of-funds': 12,
  'pending-transactions': 13,
  'fma': 14,
  'create-entry': 15,
};

const tabs = [
  'overview',
  'holdings',
  'transactions',
  'report',
  'yearly-report',
  'documents',
  'aptitude-tests',
  'registration',
  'tags',
  'portfolio-roles',
  'approvals',
  'business-profile',
  'source-of-funds',
  'pending-transactions',
  'fma',
  'create-entry',
];

const Client = ({ match, history, location, client, portfolio, userRoles }) => {
  const distributor = portfolio.distributor;
  const { profileUuid, portfolioUuid, tab } = match.params;
  const [index, setIndex] = useState(
    (tab && typeof tabIndexes[tab] !== 'undefined')
      ? tabIndexes[tab]
      : distributor === 'Hubins' ? 0 : 1);
  const backOfficeUser = isBackofficeUser(userRoles);

  const changeTab = (newIndex) => {
    const newTab = tabs[newIndex];
    history.replace({ pathname: `/dashboard/clients/${profileUuid}/${portfolioUuid}/tabs/${newTab}` });
    setIndex(newIndex);
  };

  return (
    <>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={changeTab}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list">
          <Tab className={`heading heading--5 line-tabs__tab ${distributor === 'Hubins' ? '' : 'hidden'}`}>{__('overview')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('holding')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('transactions')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('report')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('yearly_report')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('document')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('aptitude_tests')}</Tab>
          {backOfficeUser && (
            <>
              <Tab className="heading heading--5 line-tabs__tab">{__('Registration')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('tags')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('roles')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('menu_approvals')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">Business profile</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('sof')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('pending_transactions')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('risk_tool')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('create_entry')}</Tab>
            </>
          )}
        </TabList>
        <TabPanel>
          <Overview
            portfolioUuid={portfolioUuid}
            profileUuid={profileUuid}
            portfolio={portfolio}
            changeTab={setIndex}
            client={client}
            backoffice
          />
        </TabPanel>
        <TabPanel>
          <Holdings
            portfolioUuid={portfolioUuid}
            profileUuid={profileUuid}
            portfolio={portfolio}
          />
        </TabPanel>
        <TabPanel>
          <Transactions
            portfolioUuid={portfolioUuid}
            portfolio={portfolio}
          />
        </TabPanel>
        <TabPanel>
          <Report
            portfolioUuid={portfolioUuid}
            portfolio={portfolio}
          />
        </TabPanel>
        <TabPanel>
          <YearlyReport portfolio={portfolio} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <Documents profileUuid={profileUuid} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel>
          <AptitudeTests
            client={client}
          />
        </TabPanel>

        {backOfficeUser && (
          <>
            <TabPanel className={`line-tabs__tab-panel`}>
              <Registration
                profileUuid={profileUuid}
                portfolioUuid={portfolioUuid}
                contact={client}
                hash={location.hash}
                portfolio={portfolio}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <Tags
                portfolio={portfolio}
                profileUuid={profileUuid}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <Roles
                portfolio={portfolio}
                profileUuid={profileUuid}
                hash={location.hash}
              />
            </TabPanel>
            <TabPanel>
              <Approvals
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
              />
            </TabPanel>
            <TabPanel>
              <BusinessProfile
                profileUuid={profileUuid}
                portfolioUuid={portfolioUuid}
                profile={client}
                portfolio={portfolio}
              />
            </TabPanel>
            <TabPanel>
              <SourceOfFunds
                isCompany={client.type === 'COMPANY'}
                profileUuid={profileUuid}
                portfolioUuid={portfolioUuid}
              />
            </TabPanel>
            <TabPanel>
              <PendingTransactions
                portfolioUuid={portfolioUuid}
              />
            </TabPanel>
            <TabPanel>
              <RiskTool
                client={client}
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
              />
            </TabPanel>
            <TabPanel>
              <CreateEntry
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
              />
            </TabPanel>
          </>
        )}
      </Tabs>
    </>
  );
};

export default Client;
